.tabs-content {
    background-color: white;
        position: relative;
        z-index: 2;
    }
    
    .tabs-content .nav-tabs .nav-link {
        color: #484545;
        font-weight: bold;
        font-size: 14px;
        border: none;
    }

    
    .tabs-content .nav-tabs .nav-link.active {
        background-color: #001231;
        color: #a6bee8;
        border-left: 15px solid;
    }
    
    .tabs-content .cast-people {
        height: 40px;
        width: auto;
    }

    .grade-text{
    background: #FFFBFB;
        background: linear-gradient(to bottom, #FFFBFB 19%, #000000 79%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
.accordion-item {
    border: 0;
}
//   .accordion-button{
//     background-color: #001231;
//     border-radius: 0;
//     color: white;
//             filter: brightness(50%) invert(-50%);
//   }

//          .accordion-button:not(.collapsed) {
//             color: inherit;
//                 background-color: rgb(53, 65, 100);
//                 color: black;
//                 border-radius: 0;;
//          }

//     .accordion-button:not(.collapsed)::after{
//         color: white;
//         filter: brightness(100%) invert(50%);
//         border-radius: 0;
//     }
    .tab-pane-image{
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            padding: 0;
                margin-top: 0;
              
    }

    .text{
        padding: 8.3rem 2.188rem;
        height: 100%;

    }

    .textShadow{
        text-shadow: horizontal-offset vertical-offset blur color;
        text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    }

    .box {
        position: relative;
        transform-style: preserve-3d;
        border-radius: 59px;
    }

    .box::before {
        content: "";
        position: absolute;
        inset: -100px;
        background: conic-gradient(from 90deg at 90% -25%, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097);
        filter: blur(60px);
        transform: translate3d(0px, 0px, -1px);
        border-radius: inherit;
        pointer-events: none;
    }

    .box-sm {
        position: relative;
        transform-style: preserve-3d;
        border-radius: 60px;
    }

    .box-sm::before {
        content: "";
        position: absolute;
        inset: 0px;
        background: conic-gradient(from 90deg at 90% -25%, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097, #00000097);
        filter: blur(30px);
        transform: translate3d(0px, 0px, -1px);
        border-radius: inherit;
        pointer-events: none;
    }