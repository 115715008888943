@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:wght@100&display=swap');

body {
    overflow-x: hidden;
    box-sizing: border-box;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}


/* createProjectFrom style  */

.Project_form_Container {
    height: 400px;
    padding: 1rem;
    overflow-y: scroll;
}



/* stepfrom progressbar style  */
.indexedStep {
    color: black;
    width: 20px;
    height: 20px;
    font-size: 16px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: rgb(212, 213, 214);
}



/*-------------- form style  -----------*/
.form_cover {
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 75vh;
}

.uplead_form {
    background-color: rgb(216, 216, 216);
}

.from_stage {
    display: flex;
    justify-content: center;
}

.form_container {
    margin-top: 75px;
    width: 95%;
    padding: 2rem;
    margin-left: -80px;
    background-color: rgb(219, 221, 221);
    margin-bottom: 20px;
    height: 60vh;
}











/* all project page style  */
.all_project {
    min-height: 100vh;
}

.accordion-button:not(.collapsed) {
    background-color: white !important;
    box-shadow: none !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: white;
    outline: 0;
    box-shadow: none !important;
}

.accordian-items .form-control:focus {
    box-shadow: 0 0 0 0;
}

.load-more {
    cursor: pointer;
}






/* Reg  Page style  */
.reg-bg {
    background-color: rgb(236, 234, 234);
}

.reg-img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}

.reg-width {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.reg-bg .inputs {
    padding: .6rem;
}

/* .reg-background{
    background-color: rgb(212, 212, 212);
    height: 100vh;
    padding: 0px;
}

.reg-modal{
    margin-right: auto;
    margin-left: auto;
    margin-top: 12%;
    padding: 20px;
    border-radius: 5px;
    width: 40%;
} */




/* main slider style  */

.slider-width {
    width: 92%;
    margin-right: auto;
    margin-left: auto;
}

.slider-container {
    height: 150px;
    padding-right: 8px;
    padding-left: 8px;
    margin: 10px 20px;
    width: 250px;
    overflow: hidden;
}

/* .slick-active .slider-container img {
    transform: scale(1);
    z-index: 2;
    position: relative;
} */

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 25px !important;
    line-height: 1;
    opacity: .75;
    background-color: white !important;
    color: black !important;

}








/* profile page style  */
/* .profile_bg{
    background-color: white;
} */

.profile_header {
    height: 150px;
    background: rgb(158, 158, 158);
    background: linear-gradient(0deg, rgba(158, 158, 158, 1) 0%, rgba(202, 202, 204, 1) 44%, rgba(255, 255, 255, 1) 100%);
}

.profile-container {
    background-color: white;
    padding: 15px;
    border-radius: .5rem .5rem 0rem 0rem !important;
    position: relative;
    margin-top: -200px;
    z-index: 2;
}

.profile-edit {
    position: relative;
    margin-top: -20px;
    text-align: center;
    cursor: pointer;
}

.profile-delete{
    position: relative;
    top: 12px;
    margin-right: -183px;
    text-align: center;
    cursor: pointer;
    padding: 0;
}

.photo-delete{
    position: relative;
    bottom: -12px;
    left: 248px;
    z-index: 1;
    text-align: left;
    cursor: pointer;
}

.profile-detail-container {
    background-color: white;
    padding: 15px;
    border-radius: .5rem .5rem 0rem 0rem !important;
    position: relative;
    margin-top: -200px;
    z-index: 2;
}

.file-upload-boxs {
    border: 1px dashed gray;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: aliceblue;
    /* height: 150px; */
    padding: 10px;
    width: 100%;
}

.Docs-upload-boxs {
    border: 1px dashed gray;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    background-color: aliceblue;
    height: 50px;
    padding: 10px;
    width: 100%;
}

.public-profile-login-popup {
    padding: 3rem 2rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    background-color: #001231;
    z-index: 30;
    bottom: 70px;
    right: 10%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.public-profile-login-popup>h4 {
    color: white;
    text-align: center;
}




/* project detail style  */

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0px;
}

.project-profile-container {
    background-color: white;
    padding: 20px;
    border-radius: 2rem 0rem 0rem 0rem;
    position: relative;
    margin-top: -200px;
    z-index: 2;
}

.project-social-container {
    background-color: white;
    padding: 30px;
    border-radius: 0rem 2rem 0rem 0rem;
    position: relative;
    margin-top: -200px;
    z-index: 2;
}


.project-detail-container {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
}

.project-detail-container .nav-tabs .nav-link {
    color: #484545;
    font-weight: bold;
    font-size: 14px;
    border: none;
}

.project-detail-container .nav-tabs .nav-link.active {
    color: #001231;
    border-bottom: 3px solid;
}

.project-detail-container .cast-people {
    height: 40px;
    width: auto;
}



.project-videos {
    height: 380px !important;
    width: 250px !important;
}





/* Caurosel style  */
.thumbnil-img {
    height: 45vh;
    background-size: cover;
}

.carousel-indicators {
    display: none !important;
}




/* Navbar style  */

.navbar-width {
    padding: 2px;
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.form-inputs {
    position: relative;
}

.form-inputs .form-control {
    height: 45px;
}

.form-inputs .form-control:focus {
    box-shadow: none;
    border: 1px solid #000;
}

.form-inputs .bx-search {
    position: absolute;
    right: -7px;
    top: -7px;
    cursor: pointer;
}

.li-margin {
    margin-right: 10px;
}

.canvas-items {
    flex-direction: column;
}

.canvas-items a {
    text-decoration: none;
    color: darkslategray;
    font-size: 20px;
}

.search-bar {
    width: 50%;
    margin: auto;
}



/* login page  */
.login-bg {
    background-color: rgb(236, 234, 234);

}

.login-img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}

.login-width {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.input-container {
    background-color: white;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}

.login-bg .inputs {
    padding: 1rem;
}

.social-authentication {
    cursor: pointer;
}



/* search page style  */

.dahs_navbar {
    padding-left: 0px;
}

.dahs_navbar li {
    list-style: none;
    margin-top: 25px;
    margin-bottom: 25px;
}

.dahs_navbar li:hover {
    color: blue;
}

.search_inner {
    border-radius: 1px solid gray;
}

.linkStyle {
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #000;
}

li a:active {
    background-color: black;
    font-size: 30px;
}

.people_search_items {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.People_search_img {
    height: 50px;
    margin-right: 10px;
    width: auto;
}

.Project_search_img {
    height: 60px;
    margin-right: 10px;
    width: 60px;
}

/* experimental code */

.field-icon {
    float: right;
    left: -5px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

/*-utility styles-*/
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}
// Hide scrollbar for IE, Edge add Firefox
.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.resize-none{
    resize: none !important;
}

.dot-dropdown .dropdown-toggle::before{
    display: none !important;
}

.dot-dropdown .dropdown-toggle::after{
    display: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none !important; 
  margin: 0 !important; 
}

.no-link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
}

.no-link:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

