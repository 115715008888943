:root{
  --darken: rgba(213, 213, 213,1);
  --light: rgba(255, 255, 255,1);
  // --darken: rgba(255, 255, 255, 0);
  // --light: rgb(255, 247, 232);
}
.shimmer{
    height: 50px;
    background: rgb(221,221,222);
    animation: change 1.4s ease-in-out infinite ;
  }
  
  @keyframes change {
    from {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 5%, var(--darken) 100%);
    }
    10% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 10%, var(--darken) 100%);
    }
    20% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 20%, var(--darken) 100%);
    }
    30% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 30%, var(--darken) 100%);
    }
    40% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 40%, var(--darken) 100%);
    }
    50% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 50%, var(--darken) 100%);
    }
    60% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 60%, var(--darken) 100%);
    }
    70% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 70%, var(--darken) 100%);
    }
    80% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 80%, var(--darken) 100%);
    }
    90% {
      background: linear-gradient(90deg, var(--darken) 0%, var(--light) 90%, var(--darken) 100%);
    }
      to {
              background: linear-gradient(90deg, var(--darken) 0%, var(--light) 100%, var(--darken) 100%);
      }
  }